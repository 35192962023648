export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
        development: "http://datapdtdi.myappscenter.com",
        production: "http://datapdtdi.myappscenter.com", 
        // development: "https://hotelnew.myappscenter.com" ,
        // production: "https://hotelnew.myappscenter.com" 
    },
    localStorageKey : {
      'formPembelian' : '_xyZ1223lda_ead_',
      USER: "__renataKidsUser",
      ACCESS_TOKEN: "__renataKidsValue",
      PERM_GROUP : "__permGroup",
      PERM_DETAIL : "__permDetail",
      USER_PERM : "__userperm",
      DEF_FB : "__defFb", 
    },
    endPoint : {
      account : `/accounting/api/v1/account/`,
      accountOption : `/accounting/api/get-account-option/`,
      authv1 : `/auth/api/v1/`,
      bank : `/accounting/api/v1/bank/`,
      changePassword : `/auth/api/change-password`,
      contact : `/contact/api/v1/contact/`,
      jurnal : `/accounting/api/v1/jurnal/`,
      iuranAnggota : `keanggotaan/api/v1/iuran-anggota/`,
      importAnggotaSave : `/contact/api/v1/import-anggota/`,
      importAnggotaTry : `/contact/api/v1/try-import-anggota/`,
      defaultAccount : `/accounting/api/default-akun/`,
      masterIuran : `/res-app/api/v1/master-iuran/`,
      pesertaSeminar : `/seminar/api/v1/peserta-seminar/`,
      redundSeminar : `/seminar/api/v1/refund-seminar/`,
      seminar : `/seminar/api/v1/seminar/`,
      userv1 : `/auth/api/v1/user/`,
    },
    expiresSession : 86400,
    itemPerPage : 10,
    timeOutValue : 5000
};
  