import { combineReducers } from 'redux'
import userReducer from './userslice';
import provinsiReducer from './provinsi'
import kabupatenReducer from './kabupaten';
import postsReducer from './perusahaan'
import roleReducer from './roles'; 
import produkReducer from './produkSlice'; 
import penjualanReducer from './penjualanSlice'; 
import akunReducer from './accounting';
import bankReducer from './bank';
import jurnalReducer from './jurnal';


// import postReducer from './post'
// import commentsReducer from './comments'

const rootReducer = combineReducers({
    posts: postsReducer,
    provinsis : provinsiReducer,
    kabupatens : kabupatenReducer,      
    users : userReducer,
    roles : roleReducer,    
    produks : produkReducer,    
    penjualans : penjualanReducer, 
    akuns : akunReducer,   
    banks : bankReducer,
    jurnals : jurnalReducer,
})

export default rootReducer
